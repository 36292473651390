@use 'global' as g;

.flow{
  @media only screen and (max-width:g.$SmartphoneSize + px){
    .rev{
      flex-direction: column-reverse;
    }
    .wp-block-columns{
      margin-bottom: g.vw(40);
      gap: 0;
    }
  }
  > .wp-block-group__inner-container{
    > .wp-block-columns{

      h3{
        @media only screen and (max-width:g.$SmartphoneSize + px){
          margin-top: g.vw(60);
        }
        &::after{
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          color: #efefef;
          @include g.font(120,120);
          line-height: 0;
          z-index: -1;
        }
      }

      &:nth-child(1){
        h3{
          position: relative;
          &::after{
            content: '01';
          }
        }
      }
      &:nth-child(2){
        h3{
          position: relative;
          &::after{
            content: '02';
          }
        }
      }
      &:nth-child(3){
        h3{
          position: relative;
          &::after{
            content: '03';
          }
        }
      }
      &:nth-child(4){
        h3{
          position: relative;
          &::after{
            content: '04';
          }
        }
      }
      &:nth-child(5){
        h3{
          position: relative;
          &::after{
            content: '05';
          }
        }
      }
    }
  }
}
