@use 'global' as g;

.blog-head{
  padding: 40px 0;
  background: g.$black;
  color: g.$white;
  .meta{
    @include g.m(b,50,60);
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    .tags{
      @include g.m(l,40,50);
      span{
        @include g.font(14,24);
        color: g.$white;
        padding: 5px 10px;
        background: g.$gold;
        @include g.m(r,5,10);
        @include g.m(b,5,10);
        border-radius: 50px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          padding: g.vw(5) g.vw(7);
        }
      }
    }
  }
  h1{
    @include g.font(56,64);
    @include g.m(b,30,40);
  }
}
