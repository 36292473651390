@use 'global' as g;

.point{
  border-radius: 15px;
  padding: 40px;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    padding: g.vw(50);
  }
  .point_block{
    @include g.m(b,40,40);
    @include g.p(b,40,40);
    border-bottom: 1px solid g.$white;
    gap: 1em;
  }
  .wp-block-image{
    margin-bottom: 0;
  }
}
