@use 'global' as g;

#shop_trainer{
  background: #faf2e1;
  .trainer_info{
    > div{
      @include g.m(b,50,50);

      > dt{
        background: g.$white;
        text-align: center;
        border: 1px solid g.$gold;
        font-weight: 700;
        @include g.font(24,36);
        @include g.m(b,20,20);
        padding: 20px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          padding: g.vw(20);
        }
      }
      > dd{
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap;
        .eye-catch{
          width: 360px;
          img{
            border: 5px solid #efefef;
          }
          @media only screen and (max-width:g.$widthBase + px){
            width: 100%;
            text-align: center;
            img{
              @include g.m(b,20,30);
              @media only screen and (max-width:g.$SmartphoneSize + px){
                width: 100%;
              }
            }
          }
        }
        .trainer_data{
          width: 645px;
          @media only screen and (max-width:g.$widthBase + px){
            width: 100%;
          }
          dl{
            div{
              border-bottom: 1px solid #ccc;
              &:first-child{
                border-top: 1px solid #ccc;
              }
              display: flex;
              justify-content: space-between;
              flex-wrap:wrap;
              dt,dd{
                padding: 20px;
                @media only screen and (max-width:g.$SmartphoneSize + px){
                  padding: g.vw(20);
                }
              }
              dt{
                width: 200px;
                background: g.$gold;
                color: g.$white;
                font-weight: 700;
              }
              dd{
                width: calc(100% - 200px);
                border-right: 1px solid #ccc;
                background: g.$white;
                ul{
                  display: flex;
                  flex-wrap:wrap;
                  align-items: center;
                  li{
                    display: inline-block;
                    @include g.m(r,10,10);
                    padding: 5px 15px;
                    @media only screen and (max-width:g.$SmartphoneSize + px){
                      padding: g.vw(5) g.vw(15);
                    }
                    border-radius: 30px;
                    background: #ccc;
                    color: g.$white;
                    @include g.font(16,24);
                    &.on{
                      background: g.$gold;
                    }
                  }
                }
              }
            }
          }
          .comment{
            @include g.m(t,20,30);
          }
        }
      }
    }
  }
}


