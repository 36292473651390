@use 'global' as g;

.trainer-list{
  display: flex;
  flex-wrap:wrap;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    justify-content: space-between;
  }
  li{
    @media only screen and (min-width:g.$widthBase + px){
      width: 241px;
      margin-right: 20px;
      margin-bottom: 20px;
      &:nth-child(4n){
        margin-right: 0;
      }
    }
    @media only screen and (max-width:g.$widthBase + px){
      width: 32%;
      margin-right: 2%;
      margin-bottom: 20px;
      &:nth-child(3n){
        margin-right: 0;
      }
      @media only screen and (max-width:g.$SmartphoneSize + px){
        width: g.vw(340);
        margin-bottom: g.vw(30);
        margin-right: 0;
      }
    }
    img{
      width: 100%;
      object-fit: cover;
      height: 320px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        height: g.vw(450);
        width: g.vw(340);
      }
    }
    p{
      text-align: center;
      font-weight: 700;
      color: g.$white;
      background: g.$gold;
      @include g.font(20,32);
      position: relative;
      padding: 10px 0;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        padding: g.vw(20) 0;
      }
      &::after{
        position: absolute;
        right: 20px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          right: g.vw(20);
        }
        @include g.svg(block,12,16,23,37);
        @include g.arr(fff);
        margin: auto;
        top: 0;
        bottom: 0;
      }
    }
  }
}
