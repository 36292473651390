@use 'global' as g;

.main-btn {
  .wp-block-button{
    &:hover{
      opacity: .5;
    }
    background: g.$green;
    padding: 5px;
    border-radius: 100px;
    .wp-block-button__link{
      border: 2px solid g.$white;
      background: g.$green;
      padding: 20px 40px;
      width: 720px;
      @include g.font(24,36);
      position: relative;
      &::after{
        @include g.svg(block,11,16,24,37);
        @include g.arr(fff);
        margin: auto;
        top: 0;
        bottom: 0;
        right: 20px;
        position: absolute;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          right: g.vw(20);
        }
      }
      @media only screen and (max-width:g.$SmartphoneSize + px){
        width: 100%;
        padding: g.vw(20) g.vw(50);
      }
    }
  }

  &.gold{
    .wp-block-button{
      background: g.$gold;
      .wp-block-button__link{
        background: g.$gold;
      }
    }
  }

}
