@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  border: 0;
  outline: 0;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  text-decoration: none;
  color: #000;
  background-color: #ff9;
}

/* change colours to suit your needs */
mark {
  font-weight: bold;
  font-style: italic;
  color: #000;
  background-color: #ff9;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #cecece;
}

input,
select {
  vertical-align: middle;
}

ul,
ol {
  list-style-type: none;
}

input[type=submit],
input[type=button] {
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: 0;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

input[type=submit]::-webkit-search-decoration,
input[type=button]::-webkit-search-decoration {
  display: none;
}

input[type=submit]::focus,
input[type=button]::focus {
  outline-offset: -2px;
}

*, *:before, *:after {
  box-sizing: border-box;
}

ul, dl, dt, dd {
  width: 100%;
}

body {
  padding-top: 100px;
  font-size: 16px;
  line-height: 1.6;
  font-family: "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro, " メイリオ", Meiryo, Osaka, " ＭＳ Ｐゴシック", MS PGothic, " sans-serif";
  position: relative;
  color: #000;
}
@media only screen and (max-width: 750px) {
  body {
    padding-top: 17.6vw;
    font-size: 3.7333333333vw;
  }
}
body.no-scroll {
  overflow: hidden;
  touch-action: pinch-zoom;
}

img {
  max-width: 100%;
  height: auto;
}

main {
  overflow-x: hidden;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

@media only screen and (max-width: 750px) {
  .sp_center {
    text-align: center;
  }

  .sp_left {
    text-align: left;
  }
}
.inner {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 1024px) {
  .inner {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .inner {
    padding: 0 3.2vw;
  }
}

a {
  text-decoration: none;
  color: #000;
}

.pc_only {
  display: block;
}
@media only screen and (max-width: 750px) {
  .pc_only {
    display: none;
  }
}

.sp_only {
  display: none;
}
@media only screen and (max-width: 750px) {
  .sp_only {
    display: block;
  }
}

.under_tab {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .under_tab {
    display: block;
  }
}

.mb40 {
  margin-bottom: 40px;
}
@media only screen and (max-width: 750px) {
  .mb40 {
    margin-bottom: 5.3333333333vw;
  }
}

.alignfull {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}

main section, main article {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 750px) {
  main section, main article {
    padding-top: 12vw;
  }
}
@media only screen and (max-width: 750px) {
  main section, main article {
    padding-bottom: 12vw;
  }
}
main section.slick-slider, main article.slick-slider {
  padding: 0;
}
main article p {
  margin-bottom: 30px;
}
@media only screen and (max-width: 750px) {
  main article p {
    margin-bottom: 5.3333333333vw;
  }
}

.font-large {
  font-size: 1.5rem;
}
@media only screen and (max-width: 750px) {
  .font-large {
    font-size: 4.8vw;
  }
}

.font-mid {
  font-size: 1.125rem;
}
@media only screen and (max-width: 750px) {
  .font-mid {
    font-size: 4vw;
  }
}

mark {
  font-style: unset;
}

.header {
  border-bottom: 5px solid #000;
  display: block;
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10000;
  background: #fff;
}
@media only screen and (max-width: 750px) {
  .header {
    height: auto;
  }
}
.header__inner {
  padding: 20px 0;
  width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .header__inner {
    width: 100%;
    padding: 20px 40px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .header__inner {
    padding: 0;
  }
}
.header__inner .brand_name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .header__inner .brand_name {
    padding-left: 3.2vw;
  }
}
.header__inner .brand_name .logo {
  width: 140px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .header__inner .brand_name .logo {
    width: 25.3333333333vw;
  }
  .header__inner .brand_name .logo img {
    width: 19.2vw;
  }
}
.header__inner .brand_name .logo::after {
  position: absolute;
  right: 19px;
  height: 120px;
  width: 1px;
  display: block;
  background: #000;
  content: "";
  transform: rotate(17deg);
  margin: auto;
  top: 0;
  border: 0;
}
.header__inner .brand_name .shops {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  margin-left: 20px;
}
@media only screen and (max-width: 750px) {
  .header__inner .brand_name .shops {
    font-size: 3.2vw;
  }
}
@media only screen and (max-width: 750px) {
  .header__inner .brand_name .shops {
    margin-left: 2.6666666667vw;
  }
}
.header__inner .brand_name .shops span {
  color: #be9b49;
}
.header__inner #menu {
  display: none;
}
@media only screen and (max-width: 750px) {
  .header__inner #menu {
    transition: 0.5s;
    width: 17.6vw;
    height: 17.6vw;
    background: #be9b49;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 4.2666666667vw;
    position: relative;
  }
  .header__inner #menu::after {
    content: "";
    display: block;
    width: 9.0666666667vw;
    height: 0.5333333333vw;
    background: #fff;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
  }
  .header__inner #menu span {
    height: 6.4vw;
    width: 9.0666666667vw;
    position: relative;
  }
  .header__inner #menu span::after, .header__inner #menu span::before {
    content: "";
    display: block;
    width: 100%;
    height: 0.5333333333vw;
    background: #fff;
    position: absolute;
  }
  .header__inner #menu span::before {
    top: 0;
  }
  .header__inner #menu span::after {
    bottom: 0;
  }
}
.header__inner #menu.close {
  background: #fff;
}
.header__inner #menu.close:after {
  display: none;
}
.header__inner #menu.close span::after, .header__inner #menu.close span::before {
  background: #be9b49;
  margin: auto;
  top: 0;
  bottom: 0;
}
.header__inner #menu.close span::after {
  transform: rotate(-30deg);
}
.header__inner #menu.close span::before {
  transform: rotate(30deg);
}
.header__btn {
  width: 200px;
  position: relative;
  background: #be9b49;
  padding: 20px 0;
  text-align: center;
  color: #fff;
  border-radius: 5px;
}
.header__btn:hover {
  color: #fff;
  opacity: 0.8;
}
@media only screen and (max-width: 750px) {
  .header__btn {
    display: none;
  }
}
.header__btn::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 10px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E");
  margin: auto;
  top: 0;
  bottom: 0;
  right: 20px;
  position: absolute;
}

.mainvisual img {
  width: 100%;
}

.experience .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
  flex-basis: unset !important;
  flex-grow: unset !important;
}
@media only screen and (max-width: 1024px) {
  .experience .wp-block-columns {
    flex-wrap: wrap !important;
  }
}
.experience .experience_inner {
  padding: 0 10px;
}
@media only screen and (max-width: 750px) {
  .experience .experience_inner {
    padding: 0;
  }
}
.experience .name {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 0;
  margin-bottom: 20px;
}
@media only screen and (max-width: 750px) {
  .experience .name {
    font-size: 4.8vw;
  }
}
.experience .name sub {
  font-size: 1rem;
}
@media only screen and (max-width: 750px) {
  .experience .name sub {
    font-size: 3.7333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .experience .name {
    margin-bottom: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .experience .name {
    text-align: left;
    padding: 2.6666666667vw 0;
  }
}
.experience .eyecatch {
  width: 464px;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .experience .eyecatch img {
    width: 100%;
  }
}
.experience .detail {
  width: 540px;
}
@media only screen and (max-width: 1024px) {
  .experience .eyecatch, .experience .detail {
    width: 100%;
  }
}
.experience .wp-block-column {
  flex-basis: unset;
}
.experience .title {
  font-size: 1.5rem;
  padding: 20px 0;
  text-align: center;
  font-weight: 700;
}
@media only screen and (max-width: 750px) {
  .experience .title {
    font-size: 4.8vw;
  }
}
@media only screen and (max-width: 750px) {
  .experience .title {
    padding: 2vw 0;
  }
}
.experience .beforeafter {
  align-items: center;
}
.experience .item {
  width: 260px;
  padding: 20px 0 20px 10px;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .experience .item {
    font-size: 4.2666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .experience .item {
    width: 49.3333333333vw;
  }
}
.experience .item::after {
  content: "";
  display: block;
  background: url("../images/arr.png") no-repeat;
  background-size: cover;
  width: 50px;
  height: 34px;
}
@media only screen and (max-width: 1024px) {
  .experience .item::after {
    width: 30px;
    height: 22px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .experience .item::after {
    width: 10.4vw;
    height: 7.4666666667vw;
  }
}
.experience .value {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.experience .value p {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-size: 2rem;
}
@media only screen and (max-width: 750px) {
  .experience .value p {
    font-size: 4.4vw;
  }
}
.experience .value p sup {
  background: #be9b49;
  color: #fff;
  font-size: 1rem;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 750px) {
  .experience .value p sup {
    font-size: 3.8666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .experience .value p sup {
    margin-right: 1.3333333333vw;
  }
}
.experience .slick-prev, .experience .slick-next {
  text-indent: -999999px;
  border: none;
  width: 60px;
  height: 60px;
  background: #be9b49;
  z-index: 100;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
@media only screen and (max-width: 750px) {
  .experience .slick-prev, .experience .slick-next {
    width: 13.3333333333vw;
    height: 13.3333333333vw;
    margin: unset;
    top: 42.6666666667vw;
  }
}
.experience .slick-prev::before, .experience .slick-next::before {
  content: "";
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 36px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E");
}
@media only screen and (max-width: 750px) {
  .experience .slick-prev::before, .experience .slick-next::before {
    width: 4.2666666667vw;
    height: 6.6666666667vw;
  }
}
.experience .slick-prev {
  left: 0;
}
.experience .slick-prev::before {
  transform: rotate(180deg);
}
.experience .slick-next {
  right: 0;
}
.experience .slick-prev.slick-disabled:before, .experience .slick-next.slick-disabled:before {
  opacity: 1;
}
.experience .slick-prev:hover, .experience .slick-prev:focus, .experience .slick-next:hover, .experience .slick-next:focus {
  background: #be9b49;
}
.experience .slick-dots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.experience .slick-dots li {
  width: auto;
  height: auto;
  margin-right: 30px;
  border: none;
}
@media only screen and (max-width: 750px) {
  .experience .slick-dots li {
    margin-right: 4vw;
  }
}
.experience .slick-dots li:last-child {
  margin-right: 0;
}
.experience .slick-dots li button {
  border: none;
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  padding: 0;
  background: #fff;
  border-radius: 50%;
}
@media only screen and (max-width: 750px) {
  .experience .slick-dots li button {
    width: 4.6666666667vw;
    height: 4.6666666667vw;
  }
}
.experience .slick-dots li button:before {
  display: none;
}
.experience .slick-dots li.slick-active button {
  background: #be9b49;
}
.experience.experience_acc .acc_open {
  color: #fff;
  font-size: 130%;
  border: 2px solid #fff;
  border-radius: 50px;
  width: 750px;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 50px;
}
@media only screen and (max-width: 750px) {
  .experience.experience_acc .acc_open {
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .experience.experience_acc .acc_open {
    padding: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .experience.experience_acc .acc_open {
    margin-bottom: 6.6666666667vw;
  }
}
.experience.experience_acc .acc_group {
  display: none;
  padding-bottom: 40px;
}
@media only screen and (max-width: 750px) {
  .experience.experience_acc .acc_group {
    padding-bottom: 5.3333333333vw;
  }
}
.experience.experience_acc .name {
  background: #be9b49 !important;
  color: #fff !important;
  position: relative;
}

.point {
  border-radius: 15px;
  padding: 40px;
}
@media only screen and (max-width: 750px) {
  .point {
    padding: 6.6666666667vw;
  }
}
.point .point_block {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #fff;
  gap: 1em;
}
@media only screen and (max-width: 750px) {
  .point .point_block {
    margin-bottom: 5.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .point .point_block {
    padding-bottom: 5.3333333333vw;
  }
}
.point .wp-block-image {
  margin-bottom: 0;
}

.course {
  border: 5px solid #efefef;
  padding: 30px 50px;
}
@media only screen and (max-width: 750px) {
  .course {
    padding: 4vw 2.6666666667vw;
  }
}
.course .limited {
  background: #da0101;
  padding: 5px 10px;
  color: #fff;
  font-weight: 700;
  width: 160px;
  margin-right: 20px;
}
@media only screen and (max-width: 750px) {
  .course .limited {
    width: 32vw;
    padding: 1.3333333333vw 2vw;
    margin-right: 0;
  }
}
.course .first-time .wp-block-group__inner-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.course .joining {
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid #be9b49;
}
@media only screen and (max-width: 750px) {
  .course .joining {
    padding: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .course .joining {
    margin-bottom: 4vw;
  }
}
.course .joining strong {
  font-size: 130%;
  color: #be9b49;
}
.course .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr th,
.course .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td {
  padding: 20px;
}
@media only screen and (max-width: 750px) {
  .course .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr th,
.course .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td {
    padding: 2.6666666667vw;
  }
}
.course .course_info {
  border-top: 1px solid #be9b49;
}
.course .course_info .wp-block-columns {
  gap: 0;
}
.course .course_info .wp-block-columns > div {
  padding: 20px;
  border-right: 1px solid #be9b49;
  border-bottom: 1px solid #be9b49;
}
@media only screen and (max-width: 750px) {
  .course .course_info .wp-block-columns > div {
    padding: 2.6666666667vw;
  }
}
.course .course_info .wp-block-columns .th {
  background: #faf2e1;
  border-left: 1px solid #be9b49;
}
@media only screen and (max-width: 750px) {
  .course .course_info .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 50% !important;
  }
  .course .course_info .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column.th {
    flex-basis: 50% !important;
  }
}

.page .course h3 {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #fff;
  border-bottom: none;
  background: #be9b49;
}
@media only screen and (max-width: 750px) {
  .page .course h3 {
    padding-top: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .page .course h3 {
    padding-bottom: 4vw;
  }
}

.campain.wp-block-columns {
  flex-wrap: wrap !important;
}
@media only screen and (min-width: 750px) {
  .campain.wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 30% !important;
  }
  .campain.wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column img {
    width: 100%;
    height: 328px;
    object-fit: cover;
  }
}
.campain.wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
  position: relative;
}
.campain.wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column .ontitle {
  position: absolute;
  top: 0;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  width: 100%;
  height: 328px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .campain.wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column .ontitle {
    font-size: 5.8666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .campain.wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column .ontitle {
    height: 45.2vw;
  }
}
@media only screen and (max-width: 750px) {
  .campain.wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column img {
    height: 45.2vw;
    width: 100%;
    object-fit: cover;
  }
}
.campain .modal {
  z-index: 10000;
  overflow: auto;
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
}
.campain .modal.on {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.campain .modal > .wp-block-group__inner-container {
  width: 840px;
}
@media only screen and (max-width: 1024px) {
  .campain .modal > .wp-block-group__inner-container {
    width: 100%;
    padding: 0 40px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .campain .modal > .wp-block-group__inner-container {
    padding: 0 3.2vw;
  }
}
.campain .modal > .wp-block-group__inner-container img {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 1024px) {
  .campain .modal > .wp-block-group__inner-container {
    width: 100%;
  }
}
.campain .modal .wp-block-image {
  margin: 0;
}
.campain .modal .container {
  background: #fff;
  padding: 20px;
}
@media only screen and (max-width: 750px) {
  .campain .modal .container {
    padding: 2.6666666667vw;
  }
}
.campain .modal .container .title {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
}
@media only screen and (max-width: 750px) {
  .campain .modal .container .title {
    font-size: 5.8666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .campain .modal .container .title {
    margin-bottom: 5.3333333333vw;
  }
}
.campain .modal .modal-inner {
  position: relative;
}
.campain .modal .modal-inner .modal-close {
  position: absolute;
  right: -30px;
  top: -30px;
  text-indent: -99999px;
  display: inline-block;
  width: 67px;
  height: 67px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .campain .modal .modal-inner .modal-close {
    width: 13.6vw;
    height: 13.6vw;
    right: -1.3333333333vw;
    top: -6.6666666667vw;
  }
}
.campain .modal .modal-inner .modal-close::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 36px;
  height: 36px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201279.35%201279.48%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23be9b49%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22m154%2C1.22c-8.5%2C1.9-16.7%2C5.1-24.3%2C9.5-6.9%2C4.1-108.9%2C104.9-115.7%2C114.4C3.9%2C139.22%2C0%2C151.42%2C0%2C169.12s3.6%2C29.3%2C12.5%2C42.7c2.9%2C4.3%2C70.5%2C72.6%2C214.9%2C217l210.6%2C210.8-211.1%2C211.2C91.3%2C986.62%2C14.5%2C1064.12%2C12.2%2C1067.72c-8.8%2C13.7-12.1%2C24.9-12.1%2C41.9-.1%2C18.1%2C3.7%2C30.2%2C13.9%2C44.5%2C6.8%2C9.5%2C108.8%2C110.3%2C115.7%2C114.4%2C27.3%2C15.9%2C60.4%2C14.3%2C85.3-4.2%2C2.2-1.7%2C98.6-97.6%2C214.3-213.1l210.2-210.1%2C210.3%2C210.1c115.6%2C115.5%2C212%2C211.4%2C214.2%2C213.1%2C6.9%2C5.1%2C13%2C8.2%2C22.4%2C11.3%2C20.8%2C7%2C43.1%2C4.4%2C62.9-7.1%2C6.9-4.1%2C108.9-104.9%2C115.7-114.4%2C10.1-14.1%2C14-26.3%2C14-44%2C0-16.6-3.6-29-12.1-42.2-2.5-3.9-74.6-76.7-214.8-217.1l-211.1-211.2%2C210.7-210.8c220.7-220.8%2C216.2-216.1%2C221.9-230.3%2C7.5-18.7%2C7.7-39.2.4-57.2-5.3-13.2-7.4-15.7-61.5-70-28.9-29.1-55.2-54.8-58.5-57.2-14.1-10.1-26.3-14-44-14-16.6%2C0-28.9%2C3.5-42%2C12-4.1%2C2.6-72.4%2C70.2-217.2%2C214.9l-211.3%2C211.1-211.2-211.1C282.9%2C81.82%2C215.1%2C14.72%2C211%2C12.02c-6.4-4.1-14.9-7.8-23-10.1-7.9-2.2-25.8-2.6-34-.7Z%22%2F%3E%3C%2Fsvg%3E");
}
@media only screen and (max-width: 750px) {
  .campain .modal .modal-inner .modal-close::after {
    width: 8vw;
    height: 8vw;
  }
}

.comparison {
  padding: 40px;
  background: #fff;
  border-radius: 15px;
  /*
  @media only screen and (max-width:g.$widthBase + px){
    overflow: auto;
    .wp-block-flexible-table-block-table{
      width: 1000px;
    }
  }
  */
}
@media only screen and (max-width: 750px) {
  .comparison {
    padding: 1.3333333333vw;
  }
}
.comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr th, .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td {
  border-width: 10px;
  border-color: #fff;
  padding: 20px 10px;
}
@media only screen and (max-width: 750px) {
  .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr th, .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td {
    padding: 3.2vw 0.6666666667vw;
    border-width: unset;
    font-size: 2.9333333333vw;
  }
  .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr th img, .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td img {
    width: 70% !important;
    height: auto;
  }
}
.comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr th {
  background: #000;
  color: #fff;
}
.comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td:nth-child(2) {
  background: #faf2e1;
  box-shadow: inset 0px 0px 0px 5px #be9b49;
  color: #da0101;
  font-weight: 700;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td:nth-child(2) {
    box-shadow: inset 0px 0px 0px 0.4vw #be9b49;
  }
}
.comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td:nth-child(3), .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td:nth-child(4) {
  background: #efefef;
  box-shadow: inset 0px 0px 0px 5px #999999;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td:nth-child(3), .comparison .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table > table tr td:nth-child(4) {
    box-shadow: inset 0px 0px 0px 0.4vw #999999;
  }
}

@media only screen and (max-width: 750px) {
  .flow .rev {
    flex-direction: column-reverse;
  }
  .flow .wp-block-columns {
    margin-bottom: 5.3333333333vw;
    gap: 0;
  }
}
@media only screen and (max-width: 750px) {
  .flow > .wp-block-group__inner-container > .wp-block-columns h3 {
    margin-top: 8vw;
  }
}
.flow > .wp-block-group__inner-container > .wp-block-columns h3::after {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  color: #efefef;
  font-size: 7.5rem;
  line-height: 0;
  z-index: -1;
}
@media only screen and (max-width: 750px) {
  .flow > .wp-block-group__inner-container > .wp-block-columns h3::after {
    font-size: 16vw;
  }
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(1) h3 {
  position: relative;
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(1) h3::after {
  content: "01";
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(2) h3 {
  position: relative;
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(2) h3::after {
  content: "02";
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(3) h3 {
  position: relative;
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(3) h3::after {
  content: "03";
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(4) h3 {
  position: relative;
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(4) h3::after {
  content: "04";
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(5) h3 {
  position: relative;
}
.flow > .wp-block-group__inner-container > .wp-block-columns:nth-child(5) h3::after {
  content: "05";
}

.shop-list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 750px) {
  .shop-list {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 750px) {
  .shop-list li {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 40px;
  }
  .shop-list li:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 750px) {
  .shop-list li {
    width: 49%;
    margin-bottom: 6.6666666667vw;
  }
}
.shop-list li .eye-catch img {
  border: 5px solid #efefef;
}
.shop-list li .shopname {
  font-weight: 700;
  font-size: 1.5rem;
}
@media only screen and (max-width: 750px) {
  .shop-list li .shopname {
    font-size: 4.8vw;
  }
}
.shop-list li .cat span {
  font-size: 0.875rem;
  color: #fff;
  background: #be9b49;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 5px;
}
@media only screen and (max-width: 750px) {
  .shop-list li .cat span {
    font-size: 3.2vw;
  }
}
@media only screen and (max-width: 750px) {
  .shop-list li .cat span {
    margin-right: 1.3333333333vw;
  }
}
.shop-list li .cat span:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 750px) {
  .shop-list li .cat span {
    padding: 0.6666666667vw 1.3333333333vw;
  }
}

.voices-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.voices-list > div {
  width: 49%;
  margin-bottom: 20px;
}
@media only screen and (max-width: 750px) {
  .voices-list > div {
    margin-bottom: 6.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .voices-list > div {
    width: 100%;
  }
}
.voices-list > div dt {
  border: 1px solid #be9b49;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}
.voices-list > div dt img {
  width: 180px;
  height: auto;
}
@media only screen and (max-width: 750px) {
  .voices-list > div dt img {
    width: 33.6vw;
  }
}
.voices-list > div dt > div {
  background: #faf2e1;
  padding: 20px;
  width: calc(100% - 180px);
}
@media only screen and (max-width: 750px) {
  .voices-list > div dt > div {
    padding: 3.2vw;
    width: calc(100% - 33.6vw);
  }
}
.voices-list > div dt > div p {
  font-size: 1rem;
}
@media only screen and (max-width: 750px) {
  .voices-list > div dt > div p {
    font-size: 3.7333333333vw;
  }
}
.voices-list > div dt > div p.name {
  font-size: 1.5rem;
  font-weight: 700;
}
@media only screen and (max-width: 750px) {
  .voices-list > div dt > div p.name {
    font-size: 4.8vw;
  }
}
.voices-list > div dt::after {
  display: block;
  content: "";
  width: 100%;
  border-top: 1px solid #be9b49;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_2%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20327.83%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22440.19%200%20256.02%20184.17%2071.83%200%200%2071.83%20256.02%20327.83%20512%2071.83%20440.19%200%22%2F%3E%3C%2Fsvg%3E");
  background-size: 26px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #be9b49;
}
@media only screen and (max-width: 750px) {
  .voices-list > div dt::after {
    padding-top: 5.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .voices-list > div dt::after {
    padding-bottom: 5.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .voices-list > div dt::after {
    background-size: 4.9333333333vw 3.2vw;
  }
}
.voices-list > div dt.on::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_2%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20327.82%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23be9b49%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22255.99%200%200%20255.99%2071.82%20327.82%20255.99%20143.65%20440.18%20327.82%20512%20255.99%20255.99%200%22%2F%3E%3C%2Fsvg%3E");
  background-color: #fff;
}
.voices-list > div dd {
  display: none;
  padding: 20px;
  border: 1px solid #be9b49;
  border-top: none;
}

.faq__inner {
  margin-bottom: 20px;
}
@media only screen and (max-width: 750px) {
  .faq__inner {
    margin-bottom: 4vw;
  }
}
.faq__inner .question {
  border-radius: 10px;
  padding: 20px;
  padding-left: 70px;
  background: #be9b49;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .faq__inner .question {
    padding: 3.3333333333vw;
    padding-left: 11.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .faq__inner .question {
    font-size: 4vw;
  }
}
.faq__inner .question::after, .faq__inner .question::before {
  display: block;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.faq__inner .question:before {
  line-height: 1.8;
  content: "Q";
  font-size: 2.25rem;
  color: #fff;
  left: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .faq__inner .question:before {
    font-size: 6.4vw;
  }
}
@media only screen and (max-width: 750px) {
  .faq__inner .question:before {
    left: 3.3333333333vw;
  }
}
.faq__inner .question::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_2%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20327.83%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22440.19%200%20256.02%20184.17%2071.83%200%200%2071.83%20256.02%20327.83%20512%2071.83%20440.19%200%22%2F%3E%3C%2Fsvg%3E");
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 16px;
  right: 20px;
}
@media only screen and (max-width: 750px) {
  .faq__inner .question::after {
    width: 4.9333333333vw;
    height: 3.2vw;
  }
}
@media only screen and (max-width: 750px) {
  .faq__inner .question::after {
    right: 3.3333333333vw;
  }
}
.faq__inner .question.on {
  background: #fff;
  border: 1px solid #be9b49;
  color: #000;
}
.faq__inner .question.on::before {
  color: #ff6399;
}
.faq__inner .question.on::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_2%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20327.82%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23be9b49%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22255.99%200%200%20255.99%2071.82%20327.82%20255.99%20143.65%20440.18%20327.82%20512%20255.99%20255.99%200%22%2F%3E%3C%2Fsvg%3E");
}
.faq__inner .answer {
  display: none;
  background: #fff;
  margin-top: 20px;
  padding: 20px;
  padding-left: 70px;
  border-radius: 10px;
  border: 1px solid #be9b49;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .faq__inner .answer {
    margin-top: 2vw;
  }
}
@media only screen and (max-width: 750px) {
  .faq__inner .answer {
    padding: 3.3333333333vw;
    padding-left: 11.3333333333vw;
  }
}
.faq__inner .answer:before {
  font-weight: 700;
  position: absolute;
  line-height: 1.8;
  content: "A";
  font-size: 2.25rem;
  color: #be9b49;
  left: 20px;
  top: 7px;
}
@media only screen and (max-width: 750px) {
  .faq__inner .answer:before {
    font-size: 6.4vw;
  }
}
@media only screen and (max-width: 750px) {
  .faq__inner .answer:before {
    left: 3.3333333333vw;
    top: 1.3333333333vw;
  }
}

.news-list > li {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #be9b49;
}
@media only screen and (max-width: 750px) {
  .news-list > li {
    margin-bottom: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .news-list > li {
    padding-bottom: 4vw;
  }
}
.news-list > li:last-child {
  margin-bottom: 0;
}
.news-list > li a {
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
}
.news-list > li a:hover {
  color: #be9b49;
}
.news-list > li a .meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.news-list > li a .meta::after {
  margin-left: 40px;
  content: "・・・";
  display: inline-block;
}
@media only screen and (max-width: 750px) {
  .news-list > li a .meta::after {
    margin-left: 7.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .news-list > li a .meta {
    width: 100%;
    margin-bottom: 2.6666666667vw;
  }
}
.news-list > li a .title {
  margin-left: 40px;
}
@media only screen and (max-width: 750px) {
  .news-list > li a .title {
    margin-left: 7.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .news-list > li a .title {
    width: 100%;
    margin-left: 0;
  }
}

.blog-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
@media only screen and (max-width: 750px) {
  .blog-list {
    justify-content: space-between;
  }
}
.blog-list > li {
  margin-bottom: 20px;
  background: #fff;
}
@media only screen and (max-width: 750px) {
  .blog-list > li {
    margin-bottom: 4vw;
  }
}
@media only screen and (min-width: 1024px) {
  .blog-list > li {
    width: 23%;
    margin-right: 1.9%;
  }
  .blog-list > li:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .blog-list > li {
    width: 32%;
    margin-right: 2%;
  }
  .blog-list > li:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .blog-list > li {
    width: 49%;
    margin-right: 0;
  }
}
.blog-list > li a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.blog-list > li a .post-info {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .post-info {
    padding-top: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .post-info {
    padding-left: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .post-info {
    padding-right: 4vw;
  }
}
.blog-list > li a .post-info .meta {
  font-size: 1rem;
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .post-info .meta {
    font-size: 3.7333333333vw;
  }
}
.blog-list > li a .post-info .title {
  font-size: 1.125rem;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 20px;
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .post-info .title {
    font-size: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .post-info .title {
    margin-bottom: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .post-info {
    padding: 4vw;
  }
}
.blog-list > li a:hover .post-info .meta, .blog-list > li a:hover .post-info .title {
  color: #be9b49;
}
.blog-list > li a .tags {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .tags {
    padding-bottom: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .tags {
    padding-left: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .tags {
    padding-right: 4vw;
  }
}
.blog-list > li a .tags span {
  font-size: 0.875rem;
  color: #fff;
  padding: 5px 10px;
  background: #be9b49;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .tags span {
    font-size: 3.2vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .tags span {
    margin-right: 1.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .tags span {
    margin-bottom: 1.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-list > li a .tags span {
    padding: 0.6666666667vw 0.9333333333vw;
  }
}

.footer {
  background: #000;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}
.footer .sns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 35px;
}
@media only screen and (max-width: 750px) {
  .footer .sns {
    margin-bottom: 4.6666666667vw;
  }
}
.footer .sns li {
  margin-right: 25px;
}
@media only screen and (max-width: 750px) {
  .footer .sns li {
    margin-right: 10.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .footer .sns li img {
    height: 9.3333333333vw;
    width: auto;
  }
}
.footer .sns li:last-child {
  margin-right: 0;
}
.footer .footer_btns {
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .footer .footer_btns {
    margin-bottom: 4.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .footer .footer_btns li {
    width: 100%;
  }
}
.footer .footer_btns li:first-child {
  margin-right: 20px;
}
@media only screen and (max-width: 1024px) {
  .footer .footer_btns li:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .footer .footer_btns li:first-child {
    margin-bottom: 2.6666666667vw;
  }
}
.footer__btn {
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  width: 462px;
  border-radius: 100px;
  padding: 3px;
  color: #fff;
}
.footer__btn.pink {
  background: #ff6399;
}
.footer__btn.green {
  background: #0ea807;
}
.footer__btn.gold {
  background: #be9b49;
}
@media only screen and (max-width: 750px) {
  .footer__btn {
    font-size: 2.4vw;
  }
}
@media only screen and (max-width: 750px) {
  .footer__btn {
    width: 100%;
    font-size: 4vw;
  }
}
.footer__btn__inner {
  position: relative;
  text-align: center;
  border-radius: 100px;
  display: block;
  padding: 20px;
  width: 100%;
  border: 2px solid #fff;
}
@media only screen and (max-width: 750px) {
  .footer__btn__inner {
    padding: 0;
    height: 20vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
}
.footer__btn__inner span {
  color: #ffff00;
}
.footer__btn__inner:hover {
  opacity: 0.5;
}
.footer__btn__inner::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 11px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E");
  margin: auto;
  top: 0;
  bottom: 0;
  right: 20px;
  position: absolute;
}
@media only screen and (max-width: 750px) {
  .footer__btn__inner::after {
    width: 3.2vw;
    height: 4.9333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .footer__btn__inner::after {
    right: 2.6666666667vw;
  }
}
.footer__links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .footer__links {
    justify-content: space-between;
  }
}
.footer__links li {
  margin-right: 25px;
}
.footer__links li:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 1024px) {
  .footer__links li {
    margin-right: 0;
    width: 50%;
    margin-bottom: 20px;
    text-align: left;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .footer__links li {
    margin-bottom: 2.6666666667vw;
  }
}
.footer__links li a {
  color: #fff;
  font-size: 0.875rem;
}
@media only screen and (max-width: 750px) {
  .footer__links li a {
    font-size: 3.2vw;
  }
}
.footer .copy {
  text-align: center;
  color: #fff;
  font-size: 0.875rem;
}
@media only screen and (max-width: 750px) {
  .footer .copy {
    font-size: 3.2vw;
  }
}

.sp_nav {
  display: none;
  width: 100%;
  padding: 0 3.2vw 3.2vw 3.2vw;
  background: #fff;
}
.sp_nav ul {
  overflow: auto;
  height: 100vh;
}
.sp_nav ul li a {
  font-size: 3.7333333333vw;
  display: block;
  width: 100%;
  text-align: center;
  padding: 5.3333333333vw 0;
  border-bottom: 1px solid #be9b49;
}

.pc_nav .menu {
  width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .pc_nav .menu {
    width: 100%;
  }
}
.pc_nav .menu li {
  width: 20%;
}
.pc_nav .menu li a {
  display: block;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-weight: 700;
}
.pc_nav .menu li a:hover {
  background: #be9b49;
  color: #fff;
}

.page h2, .single-shops h2, .post-type-archive h2, .error404 h2 {
  text-align: center;
  font-size: 3rem;
  padding-bottom: 40px;
  margin-bottom: 40px;
  font-weight: 700;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .page h2, .single-shops h2, .post-type-archive h2, .error404 h2 {
    font-size: 6.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .page h2, .single-shops h2, .post-type-archive h2, .error404 h2 {
    padding-bottom: 6.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .page h2, .single-shops h2, .post-type-archive h2, .error404 h2 {
    margin-bottom: 6.6666666667vw;
  }
}
.page h2.white, .single-shops h2.white, .post-type-archive h2.white, .error404 h2.white {
  color: #fff;
}
.page h2::after, .single-shops h2::after, .post-type-archive h2::after, .error404 h2::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 60px;
  height: 17px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22contents%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20580%20168.18%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23b99749%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22m0%2C68.66v30.86c0%2C6.57%2C5.38%2C11.95%2C11.95%2C11.95h13.19v-54.76h-13.19c-6.57%2C0-11.95%2C5.38-11.95%2C11.95Z%22%2F%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22m568.05%2C56.71h-13.19v54.76h13.19c6.57%2C0%2C11.95-5.38%2C11.95-11.95v-30.86c0-6.57-5.38-11.95-11.95-11.95Z%22%2F%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22m526.85%2C14.82h-27.24v-5.86c0-4.92-4.03-8.95-8.95-8.95h-36.85c-4.92%2C0-8.95%2C4.03-8.95%2C8.95v50.25H135.15V8.95c0-4.92-4.03-8.95-8.95-8.95h-36.85c-4.92%2C0-8.95%2C4.03-8.95%2C8.95v5.86h-27.24c-4.45%2C0-8.1%2C3.64-8.1%2C8.1v122.35c0%2C4.45%2C3.64%2C8.1%2C8.1%2C8.1h27.24v5.86c0%2C4.92%2C4.03%2C8.95%2C8.95%2C8.95h36.85c4.93%2C0%2C8.95-4.03%2C8.95-8.95v-50.25h309.71v50.25c0%2C4.92%2C4.03%2C8.95%2C8.95%2C8.95h36.85c4.92%2C0%2C8.95-4.03%2C8.95-8.95v-5.86h27.24c4.45%2C0%2C8.1-3.64%2C8.1-8.1V22.92c0-4.45-3.64-8.1-8.1-8.1Z%22%2F%3E%3C%2Fsvg%3E");
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 750px) {
  .page h2::after, .single-shops h2::after, .post-type-archive h2::after, .error404 h2::after {
    width: 11.0666666667vw;
    height: 3.2vw;
  }
}
.page .text-h3, .page h3, .single-shops .text-h3, .single-shops h3, .post-type-archive .text-h3, .post-type-archive h3, .error404 .text-h3, .error404 h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #be9b49;
}
@media only screen and (max-width: 750px) {
  .page .text-h3, .page h3, .single-shops .text-h3, .single-shops h3, .post-type-archive .text-h3, .post-type-archive h3, .error404 .text-h3, .error404 h3 {
    font-size: 5.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .page .text-h3, .page h3, .single-shops .text-h3, .single-shops h3, .post-type-archive .text-h3, .post-type-archive h3, .error404 .text-h3, .error404 h3 {
    margin-bottom: 1.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .page .text-h3, .page h3, .single-shops .text-h3, .single-shops h3, .post-type-archive .text-h3, .post-type-archive h3, .error404 .text-h3, .error404 h3 {
    padding-bottom: 1.3333333333vw;
  }
}

.single-post h2 {
  font-size: 3rem;
  color: #be9b49;
  margin-bottom: 40px;
  font-weight: 700;
}
@media only screen and (max-width: 750px) {
  .single-post h2 {
    font-size: 7.4666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .single-post h2 {
    margin-bottom: 6.6666666667vw;
  }
}
.single-post h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #be9b49;
}
@media only screen and (max-width: 750px) {
  .single-post h3 {
    font-size: 5.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .single-post h3 {
    margin-bottom: 1.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .single-post h3 {
    padding-bottom: 1.3333333333vw;
  }
}

#page-head {
  background: #000;
  padding: 40px 0;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  #page-head {
    padding: 6.6666666667vw 0;
  }
}
#page-head .gold {
  color: #be9b49;
  font-weight: 700;
  font-size: 2rem;
}
@media only screen and (max-width: 750px) {
  #page-head .gold {
    font-size: 5.3333333333vw;
  }
}
#page-head h1 {
  color: #fff;
  font-size: 3.5rem;
}
@media only screen and (max-width: 750px) {
  #page-head h1 {
    font-size: 8.5333333333vw;
  }
}

.breadcrumb {
  padding: 20px 0;
  background: #efefef;
  font-size: 0.875rem;
}
@media only screen and (max-width: 750px) {
  .breadcrumb {
    padding: 2.6666666667vw 0;
  }
}
@media only screen and (max-width: 750px) {
  .breadcrumb {
    font-size: 3.2vw;
  }
}

.shop_gallery .slick-prev, .shop_gallery .slick-next {
  text-indent: -999999px;
  border: none;
  width: 60px;
  height: 60px;
  background: #be9b49;
  z-index: 100;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
@media only screen and (max-width: 750px) {
  .shop_gallery .slick-prev, .shop_gallery .slick-next {
    width: 13.3333333333vw;
    height: 13.3333333333vw;
  }
}
.shop_gallery .slick-prev::before, .shop_gallery .slick-next::before {
  content: "";
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 36px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E");
}
@media only screen and (max-width: 750px) {
  .shop_gallery .slick-prev::before, .shop_gallery .slick-next::before {
    width: 4.2666666667vw;
    height: 6.6666666667vw;
  }
}
.shop_gallery .slick-prev {
  left: 0;
}
.shop_gallery .slick-prev::before {
  transform: rotate(180deg);
}
.shop_gallery .slick-next {
  right: 0;
}
.shop_gallery .slick-prev.slick-disabled:before, .shop_gallery .slick-next.slick-disabled:before {
  opacity: 1;
}
.shop_gallery .slick-prev:hover, .shop_gallery .slick-prev:focus, .shop_gallery .slick-next:hover, .shop_gallery .slick-next:focus {
  background: #be9b49;
}
.shop_gallery .slick-dots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.shop_gallery .slick-dots li {
  width: auto;
  height: auto;
  margin-right: 30px;
  border: none;
}
@media only screen and (max-width: 750px) {
  .shop_gallery .slick-dots li {
    margin-right: 4vw;
  }
}
.shop_gallery .slick-dots li:last-child {
  margin-right: 0;
}
.shop_gallery .slick-dots li button {
  border: none;
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  padding: 0;
  background: #fff;
  border-radius: 50%;
}
@media only screen and (max-width: 750px) {
  .shop_gallery .slick-dots li button {
    width: 4.6666666667vw;
    height: 4.6666666667vw;
  }
}
.shop_gallery .slick-dots li button:before {
  display: none;
}
.shop_gallery .slick-dots li.slick-active button {
  background: #be9b49;
}

.shop_detail {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.shop_detail .eye-catch {
  width: 360px;
}
.shop_detail .eye-catch img {
  border: 5px solid #efefef;
}
@media only screen and (max-width: 1024px) {
  .shop_detail .eye-catch {
    width: 100%;
    text-align: center;
  }
  .shop_detail .eye-catch img {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .shop_detail .eye-catch img {
    margin-bottom: 4vw;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .shop_detail .eye-catch img {
    width: 100%;
  }
}
.shop_detail .eye-catch .cat {
  text-align: left;
  margin-top: 20px;
}
@media only screen and (max-width: 750px) {
  .shop_detail .eye-catch .cat {
    margin-top: 4vw;
  }
}
@media only screen and (max-width: 1024px) {
  .shop_detail .eye-catch .cat {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .shop_detail .eye-catch .cat {
    margin-bottom: 4vw;
  }
}
.shop_detail .eye-catch .cat span {
  font-size: 0.875rem;
  color: #fff;
  background: #be9b49;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 5px;
}
@media only screen and (max-width: 750px) {
  .shop_detail .eye-catch .cat span {
    font-size: 3.2vw;
  }
}
@media only screen and (max-width: 750px) {
  .shop_detail .eye-catch .cat span {
    margin-right: 1.3333333333vw;
  }
}
.shop_detail .eye-catch .cat span:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 750px) {
  .shop_detail .eye-catch .cat span {
    padding: 0.6666666667vw 1.3333333333vw;
  }
}
.shop_detail .shop-data {
  width: 645px;
}
@media only screen and (max-width: 1024px) {
  .shop_detail .shop-data {
    width: 100%;
  }
}
.shop_detail .shop-data > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  border-bottom: 1px solid #ccc;
}
.shop_detail .shop-data > div:first-child {
  border-top: 1px solid #ccc;
}
.shop_detail .shop-data > div dt, .shop_detail .shop-data > div dd {
  padding: 20px;
}
@media only screen and (max-width: 750px) {
  .shop_detail .shop-data > div dt, .shop_detail .shop-data > div dd {
    padding: 4vw;
  }
}
.shop_detail .shop-data > div dt {
  background: #be9b49;
  color: #fff;
  font-weight: 700;
  width: 200px;
}
@media only screen and (max-width: 750px) {
  .shop_detail .shop-data > div dt {
    width: 100%;
  }
}
.shop_detail .shop-data > div dd {
  border-right: 1px solid #ccc;
  width: calc(100% - 200px);
  background: #fff;
}
@media only screen and (max-width: 750px) {
  .shop_detail .shop-data > div dd {
    width: 100%;
    border-left: 1px solid #ccc;
  }
}
.shop_detail .gmap {
  width: 100%;
  margin-top: 40px;
}
@media only screen and (max-width: 750px) {
  .shop_detail .gmap {
    margin-top: 6.6666666667vw;
  }
}
.shop_detail .gmap iframe {
  width: 100%;
  height: 400px;
}
@media only screen and (max-width: 750px) {
  .shop_detail .gmap iframe {
    height: 42.6666666667vw;
  }
}

#shop_trainer {
  background: #faf2e1;
}
#shop_trainer .trainer_info > div {
  margin-bottom: 50px;
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div {
    margin-bottom: 6.6666666667vw;
  }
}
#shop_trainer .trainer_info > div > dt {
  background: #fff;
  text-align: center;
  border: 1px solid #be9b49;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding: 20px;
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dt {
    font-size: 4.8vw;
  }
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dt {
    margin-bottom: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dt {
    padding: 2.6666666667vw;
  }
}
#shop_trainer .trainer_info > div > dd {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#shop_trainer .trainer_info > div > dd .eye-catch {
  width: 360px;
}
#shop_trainer .trainer_info > div > dd .eye-catch img {
  border: 5px solid #efefef;
}
@media only screen and (max-width: 1024px) {
  #shop_trainer .trainer_info > div > dd .eye-catch {
    width: 100%;
    text-align: center;
  }
  #shop_trainer .trainer_info > div > dd .eye-catch img {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dd .eye-catch img {
    margin-bottom: 4vw;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dd .eye-catch img {
    width: 100%;
  }
}
#shop_trainer .trainer_info > div > dd .trainer_data {
  width: 645px;
}
@media only screen and (max-width: 1024px) {
  #shop_trainer .trainer_info > div > dd .trainer_data {
    width: 100%;
  }
}
#shop_trainer .trainer_info > div > dd .trainer_data dl div {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#shop_trainer .trainer_info > div > dd .trainer_data dl div:first-child {
  border-top: 1px solid #ccc;
}
#shop_trainer .trainer_info > div > dd .trainer_data dl div dt, #shop_trainer .trainer_info > div > dd .trainer_data dl div dd {
  padding: 20px;
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dd .trainer_data dl div dt, #shop_trainer .trainer_info > div > dd .trainer_data dl div dd {
    padding: 2.6666666667vw;
  }
}
#shop_trainer .trainer_info > div > dd .trainer_data dl div dt {
  width: 200px;
  background: #be9b49;
  color: #fff;
  font-weight: 700;
}
#shop_trainer .trainer_info > div > dd .trainer_data dl div dd {
  width: calc(100% - 200px);
  border-right: 1px solid #ccc;
  background: #fff;
}
#shop_trainer .trainer_info > div > dd .trainer_data dl div dd ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#shop_trainer .trainer_info > div > dd .trainer_data dl div dd ul li {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 15px;
  border-radius: 30px;
  background: #ccc;
  color: #fff;
  font-size: 1rem;
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dd .trainer_data dl div dd ul li {
    margin-right: 1.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dd .trainer_data dl div dd ul li {
    padding: 0.6666666667vw 2vw;
  }
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dd .trainer_data dl div dd ul li {
    font-size: 3.2vw;
  }
}
#shop_trainer .trainer_info > div > dd .trainer_data dl div dd ul li.on {
  background: #be9b49;
}
#shop_trainer .trainer_info > div > dd .trainer_data .comment {
  margin-top: 20px;
}
@media only screen and (max-width: 750px) {
  #shop_trainer .trainer_info > div > dd .trainer_data .comment {
    margin-top: 4vw;
  }
}

.wp-pagenavi {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
}

.wp-pagenavi span,
.wp-pagenavi a {
  border: 1px solid #be9b49;
  padding: 14px;
  font-size: 1.25rem;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 1;
  text-decoration: none;
}
@media only screen and (max-width: 750px) {
  .wp-pagenavi span,
.wp-pagenavi a {
    padding: 3.2vw;
  }
}
@media only screen and (max-width: 750px) {
  .wp-pagenavi span,
.wp-pagenavi a {
    font-size: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .wp-pagenavi span,
.wp-pagenavi a {
    margin-left: 1.8666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .wp-pagenavi span,
.wp-pagenavi a {
    margin-right: 1.8666666667vw;
  }
}

.wp-pagenavi span {
  background: #be9b49;
  color: #fff;
  border: none;
}

.wp-pagenavi a {
  color: #be9b49;
}

.blog-head {
  padding: 40px 0;
  background: #000;
  color: #fff;
}
.blog-head .meta {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .blog-head .meta {
    margin-bottom: 8vw;
  }
}
.blog-head .meta .tags {
  margin-left: 40px;
}
@media only screen and (max-width: 750px) {
  .blog-head .meta .tags {
    margin-left: 6.6666666667vw;
  }
}
.blog-head .meta .tags span {
  font-size: 0.875rem;
  color: #fff;
  padding: 5px 10px;
  background: #be9b49;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
}
@media only screen and (max-width: 750px) {
  .blog-head .meta .tags span {
    font-size: 3.2vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-head .meta .tags span {
    margin-right: 1.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-head .meta .tags span {
    margin-bottom: 1.3333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-head .meta .tags span {
    padding: 0.6666666667vw 0.9333333333vw;
  }
}
.blog-head h1 {
  font-size: 3.5rem;
  margin-bottom: 30px;
}
@media only screen and (max-width: 750px) {
  .blog-head h1 {
    font-size: 8.5333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .blog-head h1 {
    margin-bottom: 5.3333333333vw;
  }
}

.form {
  background: #efefef;
  padding: 40px;
}
@media only screen and (max-width: 750px) {
  .form {
    padding: 6.6666666667vw;
  }
}
.form dl > div {
  margin-bottom: 40px;
}
@media only screen and (max-width: 750px) {
  .form dl > div {
    margin-bottom: 6.6666666667vw;
  }
}
.form dl > div dt {
  margin-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .form dl > div dt {
    margin-bottom: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .form dl > div dt {
    font-size: 4vw;
  }
}
.form dl > div dt.req::after {
  content: "*";
  color: #fe0000;
  display: inline;
}
.form dl > div dd > div {
  margin-bottom: 20px;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd > div {
    margin-bottom: 4vw;
  }
}
.form dl > div dd > div .flex input {
  margin-bottom: 10px;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd > div .flex input {
    margin-bottom: 2.6666666667vw;
  }
}
.form dl > div dd p.exsample {
  color: #999;
  margin-top: 5px;
  margin-bottom: 0;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd p.exsample {
    margin-top: 2vw;
  }
}
.form dl > div dd input[type=text], .form dl > div dd input[type=email], .form dl > div dd input[type=tel], .form dl > div dd input[type=number] {
  width: 100%;
  padding: 10px;
  font-size: 100%;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd input[type=text], .form dl > div dd input[type=email], .form dl > div dd input[type=tel], .form dl > div dd input[type=number] {
    padding: 2.6666666667vw;
  }
}
.form dl > div dd input[type=text].short, .form dl > div dd input[type=email].short, .form dl > div dd input[type=tel].short, .form dl > div dd input[type=number].short {
  width: 20%;
}
.form dl > div dd input[type=date] {
  padding: 10px;
  font-size: 100%;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd input[type=date] {
    padding: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .form dl > div dd input[type=date] {
    font-size: 130%;
    border: 1px solid #000;
  }
}
.form dl > div dd input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.form dl > div dd input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.form dl > div dd input[type=date]::-webkit-clear-button {
  -webkit-appearance: none;
}
.form dl > div dd input[type=date] {
  position: relative;
}
.form dl > div dd textarea {
  width: 100%;
  padding: 10px;
  font-size: 100%;
  height: 240px;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd textarea {
    padding: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .form dl > div dd textarea {
    height: 48vw;
  }
}
.form dl > div dd select {
  width: 100%;
  font-size: 100%;
  padding: 20px;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd select {
    padding: 4vw;
  }
}
@media only screen and (max-width: 750px) {
  .form dl > div dd select {
    border: 1px solid #000;
    font-size: 130%;
  }
}
.form dl > div dd .flex {
  margin-top: 20px;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd .flex {
    margin-top: 2.6666666667vw;
  }
}
.form dl > div dd .flex p {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd .flex p br {
    display: none;
  }
}
.form dl > div dd .flex p span:first-child {
  margin-right: 20px;
}
@media only screen and (max-width: 750px) {
  .form dl > div dd .flex p span:first-child {
    margin-right: 0vw;
  }
}
@media only screen and (max-width: 750px) {
  .form dl > div dd .flex p span {
    margin-bottom: 2.6666666667vw;
  }
}
.form dl > div dd .req {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.form dl > div dd .req::after {
  content: "*";
  color: #fe0000;
  display: inline;
}
.form .send {
  width: 390px;
  height: 55px;
  position: relative;
  margin: auto;
}
@media only screen and (max-width: 750px) {
  .form .send {
    width: 100%;
    height: 16vw;
  }
}
.form .send::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 11px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E");
  margin: auto;
  top: 0;
  bottom: 0;
  right: 20px;
  position: absolute;
}
@media only screen and (max-width: 750px) {
  .form .send::before {
    width: 3.2vw;
    height: 4.9333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .form .send::before {
    right: 2.6666666667vw;
  }
}
.form .send input {
  border-radius: 30px;
  color: #fff;
  height: 55px;
  width: 100%;
  background: #be9b49;
  font-size: 100%;
  font-weight: 700;
}
@media only screen and (max-width: 750px) {
  .form .send input {
    height: 16vw;
  }
}

.trainer-list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 750px) {
  .trainer-list {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1024px) {
  .trainer-list li {
    width: 241px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .trainer-list li:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .trainer-list li {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 20px;
  }
  .trainer-list li:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 750px) {
  .trainer-list li {
    width: 45.3333333333vw;
    margin-bottom: 4vw;
    margin-right: 0;
  }
}
.trainer-list li img {
  width: 100%;
  object-fit: cover;
  height: 320px;
}
@media only screen and (max-width: 750px) {
  .trainer-list li img {
    height: 60vw;
    width: 45.3333333333vw;
  }
}
.trainer-list li p {
  text-align: center;
  font-weight: 700;
  color: #fff;
  background: #be9b49;
  font-size: 1.25rem;
  position: relative;
  padding: 10px 0;
}
@media only screen and (max-width: 750px) {
  .trainer-list li p {
    font-size: 4.2666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .trainer-list li p {
    padding: 2.6666666667vw 0;
  }
}
.trainer-list li p::after {
  position: absolute;
  right: 20px;
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E");
  margin: auto;
  top: 0;
  bottom: 0;
}
@media only screen and (max-width: 750px) {
  .trainer-list li p::after {
    right: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .trainer-list li p::after {
    width: 3.0666666667vw;
    height: 4.9333333333vw;
  }
}

.main-btn .wp-block-button {
  background: #0ea807;
  padding: 5px;
  border-radius: 100px;
}
.main-btn .wp-block-button:hover {
  opacity: 0.5;
}
.main-btn .wp-block-button .wp-block-button__link {
  border: 2px solid #fff;
  background: #0ea807;
  padding: 20px 40px;
  width: 720px;
  font-size: 1.5rem;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .main-btn .wp-block-button .wp-block-button__link {
    font-size: 4.8vw;
  }
}
.main-btn .wp-block-button .wp-block-button__link::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 11px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E");
  margin: auto;
  top: 0;
  bottom: 0;
  right: 20px;
  position: absolute;
}
@media only screen and (max-width: 750px) {
  .main-btn .wp-block-button .wp-block-button__link::after {
    width: 3.2vw;
    height: 4.9333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .main-btn .wp-block-button .wp-block-button__link::after {
    right: 2.6666666667vw;
  }
}
@media only screen and (max-width: 750px) {
  .main-btn .wp-block-button .wp-block-button__link {
    width: 100%;
    padding: 2.6666666667vw 6.6666666667vw;
  }
}
.main-btn.gold .wp-block-button {
  background: #be9b49;
}
.main-btn.gold .wp-block-button .wp-block-button__link {
  background: #be9b49;
}

.s-btn .wp-block-button__link {
  width: 390px;
  position: relative;
}
@media only screen and (max-width: 750px) {
  .s-btn .wp-block-button__link {
    width: 100%;
  }
}
.s-btn .wp-block-button__link::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  width: 11px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E");
  margin: auto;
  top: 0;
  bottom: 0;
  right: 20px;
  position: absolute;
}
@media only screen and (max-width: 750px) {
  .s-btn .wp-block-button__link::after {
    width: 3.2vw;
    height: 4.9333333333vw;
  }
}
@media only screen and (max-width: 750px) {
  .s-btn .wp-block-button__link::after {
    right: 2.6666666667vw;
  }
}
.s-btn.border .wp-block-button__link {
  border: 2px solid #fff;
}