@use 'global' as g;

.campain{
  &.wp-block-columns{
    flex-wrap: wrap!important;
  }
  @media only screen and (min-width:g.$SmartphoneSize + px){
    &.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column{
      flex-basis: 30%!important;
      img{
        width: 100%;
        height: 328px;
        object-fit: cover;
      }
    }
  }
  &.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column{
    position: relative;
    .ontitle{
      position: absolute;
      top: 0;
      @include g.font(32,44);
      font-weight: 700;
      color: g.$white;
      width: 100%;
      height: 328px;
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      align-items: center;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        height: g.vw(339);
      }
    }
    @media only screen and (max-width:g.$SmartphoneSize + px){
      img{
        height: g.vw(339);
        width: 100%;
        object-fit: cover;
      }
    }
  }


  .modal{
    z-index: 10000;
    overflow: auto;
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgb(0,0,0 ,0.8);
    top: 0;
    left: 0;
    &.on{
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      align-items: center;
    }
    > .wp-block-group__inner-container{
      width: 840px;
      @media only screen and (max-width:g.$widthBase + px){
        width: 100%;
        padding: 0 g.$lap-space;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          padding: 0 g.vw(g.$sp-space);
        }
      }
      img{
        width: 100%;
        height: auto;
      }
      @media only screen and (max-width:g.$widthBase + px){
        width: 100%;
      }
    }
    .wp-block-image{
      margin: 0;
    }
    .container{
      background: g.$white;
      padding: 20px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        padding: g.vw(20);
      }
      .title{
        text-align: center;
        @include g.font(32,44);
        font-weight: 700;
        @include g.m(b,30,40);
      }
    }
    .modal-inner{
      position: relative;
      .modal-close{
        position: absolute;
        right: -30px;
        top: -30px;
        text-indent: -99999px;
        display: inline-block;
        width: 67px;
        height: 67px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: g.vw(102);
          height: g.vw(102);
          right: g.vw(-10);
          top: g.vw(-50);
        }
        background: g.$white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-wrap:wrap;
        align-items: center;
        &::after{
          @include g.svg(block,36,36,60,60);
          background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201279.35%201279.48%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23be9b49%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22m154%2C1.22c-8.5%2C1.9-16.7%2C5.1-24.3%2C9.5-6.9%2C4.1-108.9%2C104.9-115.7%2C114.4C3.9%2C139.22%2C0%2C151.42%2C0%2C169.12s3.6%2C29.3%2C12.5%2C42.7c2.9%2C4.3%2C70.5%2C72.6%2C214.9%2C217l210.6%2C210.8-211.1%2C211.2C91.3%2C986.62%2C14.5%2C1064.12%2C12.2%2C1067.72c-8.8%2C13.7-12.1%2C24.9-12.1%2C41.9-.1%2C18.1%2C3.7%2C30.2%2C13.9%2C44.5%2C6.8%2C9.5%2C108.8%2C110.3%2C115.7%2C114.4%2C27.3%2C15.9%2C60.4%2C14.3%2C85.3-4.2%2C2.2-1.7%2C98.6-97.6%2C214.3-213.1l210.2-210.1%2C210.3%2C210.1c115.6%2C115.5%2C212%2C211.4%2C214.2%2C213.1%2C6.9%2C5.1%2C13%2C8.2%2C22.4%2C11.3%2C20.8%2C7%2C43.1%2C4.4%2C62.9-7.1%2C6.9-4.1%2C108.9-104.9%2C115.7-114.4%2C10.1-14.1%2C14-26.3%2C14-44%2C0-16.6-3.6-29-12.1-42.2-2.5-3.9-74.6-76.7-214.8-217.1l-211.1-211.2%2C210.7-210.8c220.7-220.8%2C216.2-216.1%2C221.9-230.3%2C7.5-18.7%2C7.7-39.2.4-57.2-5.3-13.2-7.4-15.7-61.5-70-28.9-29.1-55.2-54.8-58.5-57.2-14.1-10.1-26.3-14-44-14-16.6%2C0-28.9%2C3.5-42%2C12-4.1%2C2.6-72.4%2C70.2-217.2%2C214.9l-211.3%2C211.1-211.2-211.1C282.9%2C81.82%2C215.1%2C14.72%2C211%2C12.02c-6.4-4.1-14.9-7.8-23-10.1-7.9-2.2-25.8-2.6-34-.7Z%22%2F%3E%3C%2Fsvg%3E');
        }
      }
    }
  }
}
