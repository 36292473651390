@use 'global' as g;

.voices-list{
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  > div{
    width: 49%;
    @include g.m(b,20,50);
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: 100%;
    }
    dt{
      border: 1px solid g.$gold;
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
      align-items: stretch;
      img{
        width: 180px;
        height: auto;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: g.vw(252);
        }
      }
      > div{
        background: #faf2e1;
        padding: 20px;
        width: calc(100% - 180px);
        @media only screen and (max-width:g.$SmartphoneSize + px){
          padding: g.vw(24);
          width: calc(100% - g.vw(252));
        }
        p{
          @include g.font(16,28);
          &.name{
            @include g.font(24,36);
            font-weight: 700;
          }
        }
      }
      &::after{
        display: block;
        content: '';
        width: 100%;
        border-top: 1px solid g.$gold;
        text-align: center;
        @include g.p(t,20,40);
        @include g.p(b,20,40);
        @include g.arr-under(fff);
        background-size: 26px 16px;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: g.$gold;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          background-size: g.vw(37) g.vw(24);
        }
      }
      &.on{
        &::after{
          background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_2%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20327.82%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23be9b49%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22255.99%200%200%20255.99%2071.82%20327.82%20255.99%20143.65%20440.18%20327.82%20512%20255.99%20255.99%200%22%2F%3E%3C%2Fsvg%3E');
          background-color: g.$white;
        }
      }
    }
    dd{
      display: none;
      padding: 20px;
      border: 1px solid g.$gold;
      border-top: none;
    }
  }
}
