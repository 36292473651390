@use 'global' as g;

.shop_gallery{
  .slick-prev, .slick-next{
    text-indent: -999999px;
    border: none;
    width: 60px;
    height: 60px;
    background: g.$gold;
    z-index: 100;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: g.vw(100);
      height: g.vw(100);
    }
    &::before{
      content: '';
      @include g.svg(block,24,36,32,50);
      @include g.arr(fff);
    }
  }
  .slick-prev{
    left: 0;
    &::before{
      transform: rotate(180deg);
    }
  }
  .slick-next{
    right: 0;
  }
  .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before{
    opacity: 1;
  }
  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
    background: g.$gold;
  }

  .slick-dots{
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    li{
      width: auto;
      height: auto;
      margin-right: 30px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        margin-right: g.vw(30);
      }
      &:last-child{
        margin-right: 0;
      }
      border: none;
      button{
        border: none;
        text-indent: -99999px;
        width: 20px;
        height: 20px;
        padding: 0;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: g.vw(35);
          height: g.vw(35);
        }
        background: g.$white;
        border-radius: 50%;
        &:before{
          display: none;
        }
      }
      &.slick-active{
        button{
          background: g.$gold;
        }
      }
    }
  }
}



