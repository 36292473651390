@use 'global' as g;

body{
  padding-top: g.$pc-header-h + px;
  font-size: g.$baseFontSize + px;
  line-height: 1.6;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    padding-top: g.vw(g.$sp-header-h);
    font-size: g.vw(28);
  }
  font-family: g.$fontBase;
  position: relative;
  &.no-scroll{
    overflow: hidden;
    touch-action: pinch-zoom;
  }
  color: g.$black;
}

img{
  max-width:100%;
  height:auto;
}

main{
  overflow-x: hidden;
}

.center{
  text-align: center;
}

.right{
  text-align: right;
}

@media only screen and (max-width:g.$SmartphoneSize + px){
  .sp_center{
    text-align: center;
  }
  .sp_left{
    text-align: left;
  }
}

.inner{
  @include g.inner();
}

a{
  text-decoration: none;
  color: g.$black;
}

.pc_only{
  display: block;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    display: none;
  }
}

.sp_only{
  display: none;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    display: block;
  }
}

.under_tab{
  display: none;
  @media only screen and (max-width:g.$widthBase + px){
    display: block;
  }
}

.mb40{
  @include g.m(b,40,40);
}

.alignfull {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}

main{
  section,article{
    @include g.p(t,80,90);
    @include g.p(b,80,90);
    &.slick-slider{
      padding: 0;
    }
  }
  article{
    p{
      @include g.m(b,30,40);
    }
  }
}

.font-large{
  @include g.font(24,36);
}

.font-mid{
  @include g.font(18,30);
}

mark{
  font-style: unset;
}
