@use 'global' as g;

.breadcrumb{
  padding: 20px 0;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    padding: g.vw(20) 0;
  }
  background: #efefef;
  @include g.font(14,24);
}
