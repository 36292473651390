@use 'global' as g;

.header{
  border-bottom: 5px solid g.$black;
  display: block;
  height: g.$pc-header-h + px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10000;
  background: g.$white;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    height: auto;
  }
  &__inner{
    padding: 20px 0;
    width: g.$widthBase + px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    @media only screen and (max-width:g.$widthBase + px){
      width: 100%;
      padding: 20px 40px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        padding: 0;
      }
    }
    .brand_name{
      display: flex;
      flex-wrap:wrap;
      align-items: center;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        padding-left: g.vw(g.$sp-space);
      }
      .logo{
        width: 140px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: g.vw(190);
          img{
            width: g.vw(144);
          }
        }
        display: inline-flex;
        flex-wrap:wrap;
        align-items: center;
        overflow: hidden;
        position: relative;
        &::after{
          position: absolute;
          right: 19px;
          height: 120px;
          width: 1px;
          display: block;
          background: #000;
          content: "";
          transform: rotate(17deg);
          margin: auto;
          top: 0;
          border: 0;
        }
      }
      .shops{
        @include g.font(24,24);
        font-weight: 700;
        color: g.$black;
        @include g.m(l,20,20);
        span{
          color: g.$gold;
        }
      }
    }

    #menu{
      display: none;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        transition: .5s;
        width: g.vw(g.$sp-header-h);
        height: g.vw(g.$sp-header-h);
        background: g.$gold;
        display: flex;
        justify-content: center;
        flex-wrap:wrap;
        align-items: center;
        padding: g.vw(32);
        position: relative;
        &::after{
          content: '';
          display: block;
          width: g.vw(68);
          height: g.vw(4);
          background: g.$white;
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
        }
        span{
          height: g.vw(48);
          width: g.vw(68);
          position: relative;
          &::after,&::before{
            content: '';
            display: block;
            width: 100%;
            height: g.vw(4);
            background: g.$white;
            position: absolute;
          }
          &::before{
            top: 0;
          }
          &::after{
            bottom: 0;
          }
        }
      }
      &.close{
        background: g.$white;
        &:after{
          display: none;
        }
        span{
          &::after,&::before{
            background: g.$gold;
            margin: auto;
            top: 0;
            bottom: 0;
          }
          &::after{
            transform: rotate(-30deg);
          }
          &::before{
            transform: rotate(30deg);
          }
        }
      }
    }
  }
  &__btn{
    width: 200px;
    position: relative;
    background: g.$gold;
    padding: 20px 0;
    text-align: center;
    color: g.$white;
    border-radius: 5px;
    &:hover{
      color: g.$white;
      opacity: .8;
    }
    @media only screen and (max-width:g.$SmartphoneSize + px){
      display: none;
    }
    &::after{
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      width: 10px;
      height: 16px;
      @include g.arr(fff);
      margin: auto;
      top: 0;
      bottom: 0;
      right: 20px;
      position: absolute;
    }
  }
}
