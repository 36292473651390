//ベースフォントサイズ
$baseFontSize: 16;
$baseFontSize-sp: 24;
//スマホPSDの横幅
$SmartphoneSize: 750;
//ベース
$widthBase: 1024;

//ベースを割った場合のサイドのスペース
$lap-space: 40px;
//スマホ時のサイドのペース
$sp-space: 24;

//カラーの設定
$gold :#be9b49;
$white:#fff;
$black :#000;
$green: #0ea807;
$pink: #ff6399;
$yellow: #ffff00;
$gray:#e2e2e2;
$mid-gray:#999999;
$light-gray:#f9f9f9;


//赤
$red: #da0101;

//font設定
$fontBase: "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro," メイリオ", Meiryo, Osaka," ＭＳ Ｐゴシック", MS PGothic," sans-serif";


//ヘッダーの高さ
$pc-header-h: 100;
$sp-header-h: 132;


//rem() ※px指定
@function rem($context)
{
  @return calc($context / 16) * 1rem;
}

//vw()
@function vw($size) {
  @return (calc($size / $SmartphoneSize) * 100) + vw;
}

////////// mixin

//フォント
@mixin font-size($size){
  @if $size == base {
    font-size: rem(18);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(24);
    }
  }@else if $size == large {
    font-size: rem(24);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(30);
    }
  }@else if $size == small {
    font-size: rem(16);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(28);
    }
  }
}

@mixin font($pc,$sp){
  font-size: rem($pc);
  @media only screen and (max-width:$SmartphoneSize + px){
    font-size: vw($sp);
  }
}

@mixin m($part:all,$pc_size:14,$sp_size:24){
  @if $part == all {
    margin: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin: vw($sp_size);
    }
  } @else if $part == l {
    margin-left: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-left: vw($sp_size);
    }
  } @else if $part == r {
    margin-right: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-right: vw($sp_size);
    }
  } @else if $part == t {
    margin-top: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-top: vw($sp_size);
    }
  } @else if $part == b {
    margin-bottom: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw($sp_size);
    }
  }
}

@mixin p($part:all,$pc_size:14,$sp_size:24){
  @if $part == all {
    padding: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding: vw($sp_size);
    }
  } @else if $part == l {
    padding-left: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding-left: vw($sp_size);
    }
  } @else if $part == r {
    padding-right: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding-right: vw($sp_size);
    }
  } @else if $part == t {
    padding-top: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding-top: vw($sp_size);
    }
  } @else if $part == b {
    padding-bottom: $pc_size + px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding-bottom: vw($sp_size);
    }
  }
}

@mixin inner(){
  max-width: $widthBase + px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  @media only screen and (max-width:$widthBase + px){
    padding: 0 $lap-space;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding: 0 vw($sp-space);
    }
  }
}

@mixin svg($block,$pc_w,$pc_h,$sp_w,$sp_h){
  content: '';
  display: $block;
  background-repeat: no-repeat;
  background-size: cover;
  width: $pc_w + px;
  height: $pc_h + px;
  @media only screen and (max-width:$SmartphoneSize + px){
    width: vw($sp_w);
    height: vw($sp_h);
  }
}

@mixin arr($color){
  background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011.31%2016.97%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23#{$color}%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%220%202.83%205.66%208.49%200%2014.14%202.83%2016.97%208.49%2011.31%2011.31%208.49%208.49%205.66%202.83%200%200%202.83%22%2F%3E%3C%2Fsvg%3E');
}
@mixin arr-under($color){
  background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_2%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20327.83%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22440.19%200%20256.02%20184.17%2071.83%200%200%2071.83%20256.02%20327.83%20512%2071.83%20440.19%200%22%2F%3E%3C%2Fsvg%3E');
}


