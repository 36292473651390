@use 'global' as g;

.comparison{
  padding: 40px;
  background: g.$white;
  border-radius: 15px;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    padding: g.vw(10);
  }
  .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table>table tr th, .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table>table tr td{
    border-width: 10px;
    border-color: g.$white;
    padding: 20px 10px;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      padding: g.vw(24) g.vw(5);
      border-width: unset;
      font-size: g.vw(22);
      img{
        width: 70%!important;
        height: auto;
      }
    }
  }
  .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table>table tr th{
    background: g.$black;
    color: g.$white;
  }
  .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table>table tr td{
    &:nth-child(2){
      background: #faf2e1;
      box-shadow: inset 0px 0px 0px 5px rgba(190, 155, 73, 1);
      color: g.$red;
      font-weight: 700;
      text-align: center;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        box-shadow: inset 0px 0px 0px g.vw(3) rgba(190, 155, 73, 1);
      }
    }
    &:nth-child(3),&:nth-child(4){
      background: #efefef;
      box-shadow: inset 0px 0px 0px 5px rgba(153, 153, 153, 1);
      text-align: center;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        box-shadow: inset 0px 0px 0px g.vw(3) rgba(153, 153, 153, 1);
      }
    }
  }
  /*
  @media only screen and (max-width:g.$widthBase + px){
    overflow: auto;
    .wp-block-flexible-table-block-table{
      width: 1000px;
    }
  }
  */
}
