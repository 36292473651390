@use 'global' as g;

.news-list{
  > li{
    @include g.m(b,20,30);
    @include g.p(b,20,30);
    border-bottom: 1px solid g.$gold;
    &:last-child{
      margin-bottom: 0;
    }
    a{
      font-weight: 700;
      &:hover{
        color: g.$gold;
      }
      display: flex;
      flex-wrap:wrap;
      .meta{
        display: flex;
        flex-wrap:wrap;
        align-items: center;
        &::after{
          @include g.m(l,40,55);
          content: '・・・';
          display: inline-block;
        }
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: 100%;
          margin-bottom: g.vw(20);
        }
      }
      .title{
        @include g.m(l,40,55);
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
