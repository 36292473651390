@use 'global' as g;
.wp-pagenavi
{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap:wrap;
  -webkit-align-items: center;
  align-items: center;
}

.wp-pagenavi span,
.wp-pagenavi a
{
  border:1px solid g.$gold;
  @include g.p(all,14,24);
  @include g.font(20,30);
  display: block;
  @include g.m(l,10,14);
  @include g.m(r,10,14);
  line-height: 1;
  text-decoration: none;
}

.wp-pagenavi span
{
  background: g.$gold;
  color: g.$white;
  border: none;
}

.wp-pagenavi a
{
  color: g.$gold;
}

