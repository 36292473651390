@use 'global' as g;

.faq{
  &__inner{
    @include g.m(b,20,30);
    .question{
      border-radius: 10px;
      padding: 20px;
      padding-left: 70px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        padding: g.vw(25);
        padding-left: g.vw(85);
      }
      background: g.$gold;
      color: g.$white;
      @include g.font(18,30);
      font-weight: 700;
      position: relative;
      &::after,&::before{
        display: block;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
      }
      &:before{
        line-height: 1.8;
        content: 'Q';
        @include g.font(36,48);
        color: g.$white;
        left: 20px;
        display: flex;
        justify-content: center;
        flex-wrap:wrap;
        align-items: center;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          left: g.vw(25);
        }
      }
      &::after{
        @include g.arr-under(fff);
        @include g.svg(block,26,16,37,24);
        right: 20px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          right: g.vw(25);
        }
      }
      &.on{
        background: g.$white;
        border: 1px solid g.$gold;
        color: g.$black;
        &::before{
          color: #ff6399;
        }
        &::after{
          background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22_2%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20327.82%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23be9b49%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22255.99%200%200%20255.99%2071.82%20327.82%20255.99%20143.65%20440.18%20327.82%20512%20255.99%20255.99%200%22%2F%3E%3C%2Fsvg%3E');
        }
      }
    }
    .answer{
      display: none;
      background: g.$white;
      @include g.m(t,20,15);
      padding: 20px;
      padding-left: 70px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        padding: g.vw(25);
        padding-left: g.vw(85);
      }
      border-radius: 10px;
      border: 1px solid g.$gold;
      position: relative;
      &:before{
        font-weight: 700;
        position: absolute;
        line-height: 1.8;
        content: 'A';
        @include g.font(36,48);
        color: g.$gold;
        left: 20px;
        top: 7px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          left: g.vw(25);
          top: g.vw(10);
        }
      }
    }
  }
}
