@use 'global' as g;

.experience{
  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column{
    flex-basis:unset!important;
    flex-grow: unset!important;
  }
  @media only screen and (max-width:g.$widthBase + px){
    .wp-block-columns{
      flex-wrap: wrap!important;
    }
  }
  .experience_inner{
    padding: 0 10px;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      padding: 0;
    }
  }
  .name{
    text-align: center;
    font-weight: 700;
    @include g.font(24,36);
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    padding: 20px 0;
    sub{
      @include g.font(16,28);
    }
    @include g.m(b,20,20);
    @media only screen and (max-width:g.$SmartphoneSize + px){
      text-align: left;
      padding: g.vw(20) 0;
    }
  }
  .eyecatch{
    width: 464px;
    text-align: center;
    @media only screen and (max-width:g.$widthBase + px){
      img{
        width: 100%;
      }
    }
  }
  .detail{
    width: 540px;
  }
  @media only screen and (max-width:g.$widthBase + px){
    .eyecatch,.detail{
      width: 100%;
    }
  }
  .wp-block-column{
    flex-basis: unset;
  }
  .title{
    @include g.font(24,36);
    padding: 20px 0;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      padding: g.vw(15) 0;
    }
    text-align: center;
    font-weight: 700;
  }
  .beforeafter{
    align-items: center;
  }
  .item{
    width: 260px;
    padding: 20px 0 20px 10px;
    @include g.font(20,32);
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: g.vw(370);
    }
    &::after{
      content: '';
      display: block;
      background: url("../images/arr.png") no-repeat;
      background-size: cover;
      width: 50px;
      height: 34px;
      @media only screen and (max-width:g.$widthBase + px){
        width: 30px;
        height: 22px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: g.vw(78);
          height: g.vw(56);
        }
      }
    }
  }
  .value{
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    p{
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      align-items: center;
      @include g.font(32,33);
      sup{
        background: g.$gold;
        color: g.$white;
        @include g.font(16,29);
        border-radius: 20px;
        padding: 5px 10px;
        @include g.m(r,10,10);
      }
    }
  }

  .slick-prev, .slick-next{
    text-indent: -999999px;
    border: none;
    width: 60px;
    height: 60px;
    background: g.$gold;
    z-index: 100;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: g.vw(100);
      height: g.vw(100);
      margin: unset;
      top: g.vw(320);
    }
    &::before{
      content: '';
      @include g.svg(block,24,36,32,50);
      @include g.arr(fff);
    }
  }
  .slick-prev{
    left: 0;
    &::before{
      transform: rotate(180deg);
    }
  }
  .slick-next{
    right: 0;
  }
  .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before{
    opacity: 1;
  }
  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
    background: g.$gold;
  }

  .slick-dots{
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    li{
      width: auto;
      height: auto;
      margin-right: 30px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        margin-right: g.vw(30);
      }
      &:last-child{
        margin-right: 0;
      }
      border: none;
      button{
        border: none;
        text-indent: -99999px;
        width: 20px;
        height: 20px;
        padding: 0;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: g.vw(35);
          height: g.vw(35);
        }
        background: g.$white;
        border-radius: 50%;
        &:before{
          display: none;
        }
      }
      &.slick-active{
        button{
          background: g.$gold;
        }
      }
    }
  }

  &.experience_acc{

    .acc_open{
      color: g.$white;
      font-size: 130%;
      border: 2px solid g.$white;
      border-radius: 50px;
      width: 750px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        width: 100%;
      }
      @include g.p(all,20,20);
      margin:0 auto;
      @include g.m(b,50,50);
    }

    .acc_group{
      display: none;
      @include g.p(b,40,40);
    }
    .name{
      background: g.$gold!important;
      color: g.$white!important;
      position: relative;
    }
  }

}
