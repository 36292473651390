@use 'global' as g;

.blog-list{
  display: flex;
  flex-wrap:wrap;
  align-items: stretch;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    justify-content: space-between;
  }
  > li{
    @include g.m(b,20,30);
    background: g.$white;
    @media only screen and (min-width:g.$widthBase + px){
      width: 23%;
      margin-right: 1.9%;
      &:nth-child(4n){
        margin-right: 0;
      }
    }
    @media only screen and (max-width:g.$widthBase + px){
      width: 32%;
      margin-right: 2%;
      &:nth-child(3n){
        margin-right: 0;
      }
      @media only screen and (max-width:g.$SmartphoneSize + px){
        width: 49%;
        margin-right: 0;
      }
    }
    a{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
      .post-info{
        @include g.p(t,20,30);
        @include g.p(l,20,30);
        @include g.p(r,20,30);
        .meta{
          @include g.font(16,28);
        }
        .title{
          @include g.font(18,30);
          font-weight: 700;
          text-decoration: underline;
          @include g.m(b,20,20);
        }
        @media only screen and (max-width:g.$SmartphoneSize + px){
          padding: g.vw(30);
        }
      }
      &:hover{
        .post-info{
          .meta,.title{
            color: g.$gold;
          }
        }
      }
      .tags{
        @include g.p(b,20,30);
        @include g.p(l,20,30);
        @include g.p(r,20,30);
        display: flex;
        flex-wrap:wrap;
        align-items: end;
        span{
          @include g.font(14,24);
          color: g.$white;
          padding: 5px 10px;
          background: g.$gold;
          @include g.m(r,5,10);
          @include g.m(b,5,10);
          border-radius: 50px;
          @media only screen and (max-width:g.$SmartphoneSize + px){
            padding: g.vw(5) g.vw(7);
          }
        }
      }
    }
  }
}
