@use 'global' as g;

.form{
  background: #efefef;
  padding: 40px;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    padding: g.vw(50);
  }
  dl{
    > div{
      @include g.m(b,40,50);
      dt{
        @include g.m(b,10,20);
        @include g.font(18,30);
        font-weight: 700;
        display: flex;
        flex-wrap:wrap;
        align-items: center;
        &.req{
          &::after{
            content: '*';
            color: #fe0000;
            display: inline;
          }
        }
      }
      dd{
        > div{
          @include g.m(b,20,30);
          .flex{
            input{
              @include g.m(b,10,20);
            }
          }
        }
        p{
          @include g.m(10,20);
          &.exsample{
            color: #999;
            @include g.m(t,5,15);
            margin-bottom: 0;
          }
        }
        input[type="text"],input[type="email"],input[type="tel"],input[type="number"]{
          width: 100%;
          @include g.p(all,10,20);
          font-size: 100%;
          &.short{
            width: 20%;
          }
        }

        input[type=date]{
          @include g.p(all,10,20);
          font-size: 100%;
          @media only screen and (max-width:g.$SmartphoneSize + px){
            font-size: 130%;
            border: 1px solid g.$black;
          }
        }

        input[type=date]::-webkit-calendar-picker-indicator {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
        }

        input[type="date"]::-webkit-inner-spin-button{
          -webkit-appearance: none;
        }

        input[type="date"]::-webkit-clear-button{
          -webkit-appearance: none;
        }

        input[type="date"]{
          position: relative;
        }

        textarea{
          width: 100%;
          @include g.p(all,10,20);
          font-size: 100%;
          height: 240px;
          @media only screen and (max-width:g.$SmartphoneSize + px){
            height: g.vw(360);
          }
        }

        select{
          width: 100%;
          font-size: 100%;
          @include g.p(all,20,30);
          @media only screen and (max-width:g.$SmartphoneSize + px){
            border: 1px solid g.$black;
            font-size: 130%;
          }
        }

        .flex{
          @include g.m(t,20,20);
          p{
            width: 100%;
            display: flex;
            flex-wrap:wrap;
            align-items: center;
            @media only screen and (max-width:g.$SmartphoneSize + px){
              br{
                display: none;
              }
            }
            span{
              &:first-child{
                @include g.m(r,20,0);
              }
              @media only screen and (max-width:g.$SmartphoneSize + px){
                margin-bottom: g.vw(20);
              }
            }
          }
        }

        .req{
          display: flex;
          flex-wrap:wrap;
          align-items: center;
          &::after{
            content: '*';
            color: #fe0000;
            display: inline;
          }
        }
      }
    }
  }
  .send{
    width: 390px;
    height: 55px;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: 100%;
      height: g.vw(120);
    }
    position: relative;
    &::before{
      @include g.svg(block,11,16,24,37);
      @include g.arr(fff);
      margin: auto;
      top: 0;
      bottom: 0;
      right: 20px;
      position: absolute;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        right: g.vw(20);
      }
    }
    margin: auto;
    input{
      border-radius: 30px;
      color: g.$white;
      height: 55px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        height: g.vw(120);
      }
      width: 100%;
      background: g.$gold;
      font-size: 100%;
      font-weight: 700;
    }
  }
}
