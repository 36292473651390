@use 'global' as g;

.pc_nav{
  .menu{
    width: g.$widthBase + px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    @media only screen and (max-width:g.$widthBase + px){
      width: 100%;
    }
    li{
      width: calc(100% / 5);
      a{
        display: block;
        width: 100%;
        text-align: center;
        padding: 20px 0;
        font-weight: 700;
        &:hover{
          background: g.$gold;
          color: g.$white;
        }
      }
    }
  }
}
