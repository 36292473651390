@use 'global' as g;

.s-btn{
  .wp-block-button__link{
    width: 390px;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: 100%;
    }
    position: relative;
    &::after{
      @include g.svg(block,11,16,24,37);
      @include g.arr(fff);
      margin: auto;
      top: 0;
      bottom: 0;
      right: 20px;
      position: absolute;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        right: g.vw(20);
      }
    }
  }

  &.border{
    .wp-block-button__link{
      border: 2px solid g.$white;
    }
  }

}
