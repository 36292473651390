@use 'global' as g;

.course{
  border: 5px solid #efefef;
  padding: 30px 50px;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    padding: g.vw(30) g.vw(20);
  }

  .limited{
    background: g.$red;
    padding: 5px 10px;
    color: g.$white;
    font-weight: 700;
    width: 160px;
    margin-right: 20px;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: g.vw(240);
      padding: g.vw(10) g.vw(15);
      margin-right: 0;
    }
  }

  .first-time{
    .wp-block-group__inner-container{
      display: flex;
      justify-content: center;
      flex-wrap:wrap;
      align-items: center;
    }
  }

  .joining{
    @include g.p(all,20,20);
    @include g.m(b,30,30);
    border: 1px solid g.$gold;
    strong{
      font-size: 130%;
      color: g.$gold;
    }
  }

  .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table>table tr th,
  .wp-block-flexible-table-block-table.wp-block-flexible-table-block-table>table tr td{
    @include g.p(all,20,20);
  }



.course_info{
  border-top: 1px solid g.$gold;
  .wp-block-columns{
    gap: 0;
    > div{
      @include g.p(all,20,20);
      border-right: 1px solid g.$gold;
      border-bottom: 1px solid g.$gold;
    }
    .th{
      background: #faf2e1;
      border-left: 1px solid g.$gold;
    }
  }
  @media only screen and (max-width:g.$SmartphoneSize + px){
    .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column{
      flex-basis: 50%!important;
      &.th{
        flex-basis: 50%!important;
      }
    }
  }
}


}

.page{
  .course{
    h3{
      @include g.p(t,10,30);
      @include g.p(b,10,30);
      margin-bottom: 0;
      color: g.$white;
      border-bottom: none;
      background: g.$gold;
    }
  }
}
