@use 'global' as g;

#page-head{
  background: g.$black;
  padding: 40px 0;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    padding: g.vw(50) 0;
  }
  text-align: center;
  .gold{
    color: g.$gold;
    font-weight: 700;
    @include g.font(32,40);
  }
  h1{
    color: g.$white;
    @include g.font(56,64);
  }
}
