@use 'global' as g;

.page,.single-shops,.post-type-archive,.error404{
  h2{
    text-align: center;
    @include g.font(48,50);
    @include g.p(b,40,50);
    @include g.m(b,40,50);
    font-weight: 700;
    &.white{
      color: g.$white;
    }
    position: relative;
    &::after{
      @include g.svg(block,60,17,83,24);
      background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20id%3D%22contents%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20580%20168.18%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23b99749%3Bstroke-width%3A0px%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22m0%2C68.66v30.86c0%2C6.57%2C5.38%2C11.95%2C11.95%2C11.95h13.19v-54.76h-13.19c-6.57%2C0-11.95%2C5.38-11.95%2C11.95Z%22%2F%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22m568.05%2C56.71h-13.19v54.76h13.19c6.57%2C0%2C11.95-5.38%2C11.95-11.95v-30.86c0-6.57-5.38-11.95-11.95-11.95Z%22%2F%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22m526.85%2C14.82h-27.24v-5.86c0-4.92-4.03-8.95-8.95-8.95h-36.85c-4.92%2C0-8.95%2C4.03-8.95%2C8.95v50.25H135.15V8.95c0-4.92-4.03-8.95-8.95-8.95h-36.85c-4.92%2C0-8.95%2C4.03-8.95%2C8.95v5.86h-27.24c-4.45%2C0-8.1%2C3.64-8.1%2C8.1v122.35c0%2C4.45%2C3.64%2C8.1%2C8.1%2C8.1h27.24v5.86c0%2C4.92%2C4.03%2C8.95%2C8.95%2C8.95h36.85c4.93%2C0%2C8.95-4.03%2C8.95-8.95v-50.25h309.71v50.25c0%2C4.92%2C4.03%2C8.95%2C8.95%2C8.95h36.85c4.92%2C0%2C8.95-4.03%2C8.95-8.95v-5.86h27.24c4.45%2C0%2C8.1-3.64%2C8.1-8.1V22.92c0-4.45-3.64-8.1-8.1-8.1Z%22%2F%3E%3C%2Fsvg%3E');
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .text-h3,h3{
    @include g.font(32,40);
    font-weight: 700;
    @include g.m(b,10,10);
    @include g.p(b,10,10);
    border-bottom: 1px solid g.$gold;
  }

}

.single-post{
  h2{
    @include g.font(48,56);
    color: g.$gold;
    @include g.m(b,40,50);
    font-weight: 700;
  }
  h3{
    @include g.font(32,40);
    font-weight: 700;
    @include g.m(b,10,10);
    @include g.p(b,10,10);
    border-bottom: 1px solid g.$gold;
  }


}
