@use 'global' as g;

.sp_nav{
  display: none;
  width: 100%;
  padding: 0 g.vw(g.$sp-space) g.vw(g.$sp-space) g.vw(g.$sp-space);
  background: g.$white;
  ul{
    overflow: auto;
    height: 100vh;
    li{
      a{
        font-size: g.vw(28);
        display: block;
        width: 100%;
        text-align: center;
        padding: g.vw(40) 0;
        border-bottom: 1px solid g.$gold;
      }
    }
  }
}
