@use 'global' as g;

.footer{
  background: g.$black;
  color: g.$white;
  padding: 40px 0;
  text-align: center;

  .sns{
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    @include g.m(b,35,35);
    li{
      @include g.m(r,25,80);
      @media only screen and (max-width:g.$SmartphoneSize + px){
        img{
          height: g.vw(70);
          width: auto;
        }
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .footer_btns{
    @include g.m(b,35,35);
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    li{
      @media only screen and (max-width:g.$SmartphoneSize + px){
        width: 100%;
      }
      &:first-child{
        margin-right: 20px;
        @media only screen and (max-width:g.$widthBase + px){
          margin-right: 0;
          @include g.m(b,20,20);
        }
      }
    }
  }

  &__btn{
    &.pink{
      background: g.$pink;
    }
    &.green{
      background: g.$green;
    }
    &.gold{
      background: g.$gold;
    }
    display: block;
    @include g.font(18,18);
    font-weight: 700;
    width: 462px;
    border-radius: 100px;
    padding: 3px;
    color: g.$white;
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: 100%;
      font-size: g.vw(30);
    }
    &__inner{
      position: relative;
      text-align: center;
      border-radius: 100px;
      display: block;
      padding: 20px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        padding: 0;
        height: g.vw(150);
        display: flex;
        justify-content: center;
        flex-wrap:wrap;
        align-items: center;
      }
      width: 100%;
      border: 2px solid #fff;
      span{
        color: g.$yellow;
      }
      &:hover{
        opacity: .5;
      }
      &::after{
        @include g.svg(block,11,16,24,37);
        @include g.arr(fff);
        margin: auto;
        top: 0;
        bottom: 0;
        right: 20px;
        position: absolute;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          right: g.vw(20);
        }
      }
    }
  }

  &__links{
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    align-items: center;
    @media only screen and (max-width:g.$widthBase + px){
      justify-content: space-between;
    }
    li{
      margin-right: 25px;
      &:last-child{
        margin-right: 0;
      }
      @media only screen and (max-width:g.$widthBase + px){
        margin-right: 0;
        width: 50%;
        @include g.m(b,20,20);
        text-align: left;
      }
      a{
        color: g.$white;
        @include g.font(14,24);
      }
    }
  }

  .copy{
    text-align: center;
    color: g.$white;
    @include g.font(14,24);
  }

}
