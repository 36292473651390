@use 'global' as g;

.shop-list{
  display: flex;
  flex-wrap:wrap;
  @media only screen and (max-width:g.$SmartphoneSize + px){
    justify-content: space-between;
  }
  li{
    @media only screen and (min-width:g.$SmartphoneSize + px){
      width: 32%;
      margin-right: 2%;
      &:nth-child(3n){
        margin-right: 0;
      }
      margin-bottom: 40px;
    }
    @media only screen and (max-width:g.$SmartphoneSize + px){
      width: 49%;
      margin-bottom: g.vw(50);
    }
    .eye-catch{
      img{
        border: 5px solid #efefef;
      }
    }
    .shopname{
      font-weight: 700;
      @include g.font(24,36);
    }
    .cat{
      span{
        @include g.font(14,24);
        color: g.$white;
        background: g.$gold;
        border-radius: 20px;
        padding: 5px 10px;
        @include g.m(r,5,10);
        &:last-child{
          margin-right: 0;
        }
        @media only screen and (max-width:g.$SmartphoneSize + px){
          padding: g.vw(5) g.vw(10);
        }
      }
    }
  }
}
