@use 'global' as g;

.shop_detail{
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  .eye-catch{
    width: 360px;
    img{
      border: 5px solid #efefef;
    }
    @media only screen and (max-width:g.$widthBase + px){
      width: 100%;
      text-align: center;
      img{
        @include g.m(b,20,30);
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: 100%;
        }
      }
    }
    .cat{
      text-align: left;
      @include g.m(t,20,30);
      @media only screen and (max-width:g.$widthBase + px){
        @include g.m(b,20,30);
      }
      span{
        @include g.font(14,24);
        color: g.$white;
        background: g.$gold;
        border-radius: 20px;
        padding: 5px 10px;
        @include g.m(r,5,10);
        &:last-child{
          margin-right: 0;
        }
        @media only screen and (max-width:g.$SmartphoneSize + px){
          padding: g.vw(5) g.vw(10);
        }
      }
    }
  }
  .shop-data{
    width: 645px;
    @media only screen and (max-width:g.$widthBase + px){
      width: 100%;
    }
    > div{
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
      align-items: stretch;
      border-bottom: 1px solid #ccc;
      &:first-child{
        border-top: 1px solid #ccc;
      }
      dt,dd{
        padding: 20px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          padding: g.vw(30);
        }
      }
      dt{
        background: g.$gold;
        color: g.$white;
        font-weight: 700;
        width: 200px;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: 100%;
        }
      }
      dd{
        border-right: 1px solid #ccc;
        width: calc(100% - 200px);
        background: g.$white;
        @media only screen and (max-width:g.$SmartphoneSize + px){
          width: 100%;
          border-left: 1px solid #ccc;
        }
      }
    }
  }
  .gmap{
    width: 100%;
    @include g.m(t,40,50);
    iframe{
      width: 100%;
      height: 400px;
      @media only screen and (max-width:g.$SmartphoneSize + px){
        height: g.vw(320);
      }
    }
  }
}
